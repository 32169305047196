:root {
  --is5Orange: #ea7728;
}

.iS5Orange {
  color: var(--is5Orange);
}

.App-Container {
    display: flex;
    flex-direction: row;
    height: 100%;
    min-height: 100%;
    flex-grow: 1
}

.App-Body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    flex-grow: 1;
}

.NavBar-Container {
    width: 250;
    height: 100%;
    background-color: white;
}

#LoginPage {
    max-width: 1000px;
    text-align: center;
}

#NavBar .NavBar-Button {
    padding-top: 0px;
    padding-bottom: 0px;
    padding-left: 0px;
    padding-right:0px;
    background-color: inherit;
}

#NavBar .NavBar-Button:hover {
    padding-top: 0px;
    padding-bottom: 0px;
    padding-left: 0px;
    padding-right:0px;
    background-color: inherit;
}

.Input-Fields {
    display: flex;
    flex-direction: column;
    align-items: center;
}

label {
    width: 100%;
    padding: 7px;
    font-size: medium;
    font-weight: bold;
    margin-bottom: 0;
}

.Login-Fields {
    background-color: #ededed;
    width: 100%;
    height: 30px;
}

a {
  color: #0255A9;
}

a:hover {
  color: #272136;
}

.Sign-In-Button {
    background-color: var(--is5Orange);
    padding: 4px;
    font-size: 14px;
    border-radius: 6px;
    color: white;
    width: 125px;
    margin: 4px;
}

.Cancel-Button {
    background-color: rgba(0, 0, 0, 0.1);
    padding: 4px;
    font-size: 14px;
    border-radius: 6px;
    color: #888888;
    width: 125px;
    margin: 4px;
}

.Login-Page {
    font-family: 'Roboto', sans-serif;
}

.Login-Title {
    text-align: center;
    padding: 40px;
    font-weight: bold;
    font-size: x-large;
}

.File-Input {
    margin-top: 0px;
    margin-bottom: 10px;
}
  
.File-Info-Entries {
    font-size: 14px;
    font-weight: normal;
}
  
.File-Info-Border {
    border: 1px solid #e5e5ea;
    border-radius: 6px;
}
  
.File-Info-Border-Padding {
    padding: 20px;
}
  
.File-Info-Content {
    flex-direction: column;
}
  
.Page-Title {
    padding: 30px;
}
  
.Page-Content {
    display: inline-block;
}
  
.Page-Grid {
    text-align: initial;
}

button {
    background-color: transparent;
}

.Manage-Users-Table {
    font-size: 12px;
}

.Table-Entries {
    font-size: 10px;
}

.Popup {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    background-color: rgba(0,0,0, 0.5);
}
.Popup-Inner {
    position: absolute;
    left: 25%;
    right: 25%;
    top: 25%;
    bottom: 25%;
    margin: auto;
    background: white;
}

#Form .rs-form-group {
    margin-bottom: 5px;
    flex-direction: row;
    display: flex;
    justify-content: left;
    align-items: stretch;
}
  
.Form-Box {
    margin-bottom: 3px;
}
  
.Header {
    margin-top: 10px;
    margin-bottom: 20px;
    font-size: 50px;
}
  
#Form .rs-btn-toolbar {
    margin-top: 13px;
    margin-bottom: 24px;
}
  
#Form .rs-form-control-wrapper {
    justify-content: center;
}

.Form-Input {
    width: 300px;
}

.Form-Input-Label {
    width: 100%;
}

#FilesPage .rs-form-group {
    margin-bottom: 10px;
}
  
.Text-Box-Row {
    flex-direction: row;
    display: flex;
    justify-content: left;
    align-items: stretch;
}
  
#Form .rs-control-label {
    display: flex;
    width: 220px;
    align-items: center;
    margin-bottom: 0px;
    justify-content: center;
}

.Date-Picker-Label {
    padding-top: 8px;
    padding-bottom: 4px;
}

.Date-Picker {
    padding-bottom: 5px;
}

.Title {
    font-weight: bold;
}

.Files-Container {
    font-family: 'Roboto', sans-serif;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    height: 100%;
}

.Files-Header {
    width: 100%;
}

.Portal-Footer {
    width: 100%;
    height: 80px;
    background-color: #eeeeee;
    display: flex;
    align-items: center;
    justify-content: center;
}

.Portal-Footer-Text {
    color: #545456;
    font-size: 17px;
}

.Portal-Footer-Separator {
    padding: 10px;
    font-size: 17px;
    color: var(--is5Orange);
}

.Files-Sidebar {
    background-color: #f5f5f5;
}

.Files-Sidebar {
    flex: 1;
    max-width: 250px;
}

.Files-List {
    flex: 5;
    min-height: 80vh;
}

.MainView {
    padding-left: 80px;
    padding-right: 80px;
}

.MyFilesLabel {
    font-weight: bold;
    font-size: 46px;
    color: "#545456";
}

.Table {
    display: table;
    width: 100%;
    border: 1px solid #a5a5a5;
}

.TableHeading {
    font-weight: bold;
    text-align: center;
    background-color: #eeeeee;
    display: table-header-group;
}

.TableBody {
    display:table-row-group;
}

.TableRow {
    display: table-row;
}

.TableCell {
    display: table-cell;
    border: 1px solid #a5a5a5;
    padding-left: 25px;
    padding-right: 25px;
    padding-top: 10px;
    padding-bottom: 10px;
    text-align: center;
}

.TablePagination {
    font-size: 18px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    vertical-align: middle;
    padding-top: 30px;
    padding-bottom: 30px;
}

.TablePaginationButton {
    color: #888888;
    vertical-align: initial;
}

.TablePagePrevious {
    width: 33%;
    text-align: left;
}

.TablePageCount {
    width: 33%;
    text-align: center;
}

.TablePageNext {
    width: 33%;
    text-align: right;
}

.PortalExpandable {
    padding-left: 20px;
}

.PortalExpandableTitle {
    display: flex;
    flex-direction: row;
    text-align: left;
}

.PortalExpandableOpen {
}

.PortalExpandableButtonWrapper {
    display: inline-block;
    position: absolute;
}

.PortalExpandableButton {
    font-weight: bold;
    display: inline-block;
    padding: 7px 0;
}

.PortalExpandableLabel {
    padding: 7px;
    padding-left: 20px;
    width: 100%;
    font-size: medium;
    font-weight: bold;
    margin-bottom: 0;
}

.PortalExpandableSubLabel {
    padding: 7px;
    padding-left: 20px;
    width: 100%;
    font-weight: bold;
    margin-bottom: 0;
}

.PortalExpandable > .PortalExpandableTitle {
    color: black;
}

.PortalExpandableOpen.PortalExpandable > .PortalExpandableTitle {
    color: var(--is5Orange);
}

.PortalExpandable > .PortalExpandableTitle > .PortalExpandableButtonWrapper > .PortalExpandableButton::before {
    content: '+';
}

.PortalExpandableOpen.PortalExpandable > .PortalExpandableTitle > .PortalExpandableButtonWrapper > .PortalExpandableButton::before {
    content: '-';
}

.PortalExpandable > .PortalExpandableNode {
    display: none;
}

.PortalExpandableOpen.PortalExpandable > .PortalExpandableNode {
    display: block;
}

.PortalCheckTreeItem {
    padding-left: 20px;
}

.PortalCheckTreeItemLabel {
    padding-left: 10px;
}

.SearchBar {
    padding: 30px;
}

.FilterLabel {
    padding-left: 20px;
    color: black;
}

.DateRangeFilter {
    padding-left: 20px;
}

.DateRangeFilter > label {
    padding-top: 20px;
    font-size: 14px;
    color: #888888;
}

.Register-Step {
    font-size: 18px;
}

.Register-Step > span {
    font-weight: bold;
}

.Register-Sub-Step {
    font-size: 14px;
}